import { ProfessionType } from "@api/models";

type ProfessionRecord = Record<ProfessionType, string>;

export const ProfessionRadioButtonRecord: ProfessionRecord = {
  [ProfessionType.EducationalInstitution]: "Educational Institution",
  [ProfessionType.HealthcareOrganization]: "Healthcare Organization",
  [ProfessionType.HealthcareTalent]: "Healthcare Talent",
  [ProfessionType.JobSeeker]: "Job Seeker",
  [ProfessionType.RecruitmentAgency]: "Recruitment Agency",
  [ProfessionType.Other]: "Other",
};
