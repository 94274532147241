/* tslint:disable */
/* eslint-disable */
export enum ProfessionType {
  EducationalInstitution = 'EducationalInstitution',
  HealthcareOrganization = 'HealthcareOrganization',
  HealthcareTalent = 'HealthcareTalent',
  JobSeeker = 'JobSeeker',
  RecruitmentAgency = 'RecruitmentAgency',
  Other = 'Other'
}
